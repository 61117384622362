@use "./colors" as colors;

.btn-mango--base {
  // padding: 0.75rem 1.25rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.btn-mango--regular {
  transition: background-color 500ms, transform 500ms;
  background-color: colors.$color-yl-mango;

  &:focus-visible {
    outline-color: colors.$color-yl-mango;
  }

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background-color: hsl(37 91% 45% / 1);
  }
}

.btn-mango--disabled {
  background-color: colors.$color-pl-mango;
  pointer-events: none;

  &:focus-visible {
    outline-color: colors.$color-pl-mango;
  }
}

// div:has(button.btn-mango--disabled) {
//   cursor: not-allowed;
// }

a:focus-visible {
  outline-color: colors.$color-yl-mango;
}

.progress-svg {
  width: 1.5rem;
  height: 1.5rem;
  stroke-width: 0;
}

.input--padding {
  padding: 0.625rem 0.875rem;
}

.input--top-margin {
  margin-top: 0.38rem;
}

.input__otp {
  font-size: 1.5625rem;
  min-width: 0;
  width: 100%;
}

.label--bottom-margin {
  margin-bottom: 0.38rem;
}

.phone-input {
  padding: 0.625rem 0.875rem 0.625rem 5rem;
}

// This works by setting the 'required' attribute on select
// https://stackoverflow.com/a/65356961/7935926
.base__select {
  &:invalid {
    color: colors.$color-gy-light;
  }
}

.resend-otp__container {
  border: 1px solid colors.$color-fw-f8;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  & > p {
    letter-spacing: 0.015rem;
    line-height: 120%;
  }
}

.mango-logo {
  width: 10.125rem;
  height: 2.6875rem;
  flex-shrink: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.ng-touched.ng-dirty.ng-invalid + .password-icon__wrapper {
  transform: translate(0px, -11px);
}
