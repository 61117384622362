/* You can add global styles to this file, and also import other style files */
// @use "@ngneat/hot-toast/src/styles/styles.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/styles/index";
@import '@angular/cdk/overlay-prebuilt.css';
// @import 'ngx-toastr/toastr';

.adba{
    transform: t
}