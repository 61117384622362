$color-yl-mango: #f4a01c;
$color-pl-mango: #e6c28a;
$color-bq-mango: #ffe6c2;
$color-yl-f9: #fff9f1;
$color-gy-mango: #3f4951;
$color-bl-mango: #06080f;
$color-gy-700: #344054;
$color-gy-light: #bdbdbd;
$color-bl-17: #171717;
$color-gy-300: #d0d5dd;
$color-gy-74: #747474;
$color-gy-d9: #d9d9d9;
$color-rd-43: #ee4343;
$color-fw-f8: #fff8ee;
